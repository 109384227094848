

const EventInfo = {
    crn: undefined,
    title: undefined,
    contentRating: undefined,
    serie: undefined,
    genre: undefined,
    value: 0,
    isLive: false,
    viewTime: 0,
    duration: 0,
    currentTime: 0,
    percent: 0,
    error: undefined
};
/**
 * Send Google Analytics Events
 * @param {string} eventName 
 * @param {object} EventInfo 
 *
 */
const sendGoogleAnalyticsEvents = function (eventName, info = EventInfo) {

    let source = info.live ? 'live' : 'vod';

    if (window.dataLayer) {
        window.dataLayer.push({
            'event': eventName,
            "video_source": source,
            "video_provider": "web",
            "video_duration": info.duration,
            "video_current_time": info.currentTime,
            "video_percent": info.percent,
            "video_crn": info.crn,
            "video_title": info.title,
            "video_content_rating": info.contentRating,
            "video_serie": info.serie,
            "video_genre": info.genre,
            "video_view_time": info.viewTime,
            "error": info.error
        });
    }

};

export default sendGoogleAnalyticsEvents;