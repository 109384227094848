


export default class VideoJump {

    player;
    elementsObject;
    constructor(player, elementsObject) {

        this.player = player;

        this.elementsObject = {
            elementClass: elementsObject.elementClass,
            elementAttr: elementsObject.elementAttr,
            observe: elementsObject.observe || false
        };
    }

    listener() {

        if (!this.elementsObject.observe) {
            return;
        }
        this.getElements();

    }

    getElements() {
        const elements = document.querySelectorAll('.' + this.elementsObject.elementClass);
        elements.forEach((el) => {
            // add class to all elements
            el.classList.add('vjs-jump');
            // startObserver
            this.observer(el);

        });

    }

    observer(element) {
        element.addEventListener('click', (event) => {
            //@ts-ignore
            this.jumpTo(event.target);
        });

    }

    jumpTo(elementClicked) {
        // get attribute value and split
        //@ts-ignore
        let attrValue = elementClicked.getAttribute(this.elementsObject.elementAttr).split(',');
        // get minutes and seconds and convert to seconds
        let start = (parseInt(attrValue[0], 10) * 60) + parseInt(attrValue[1], 10);
        this.player.currentTime(start);
    }



}