import videojs from 'video.js';
import 'videojs-contrib-eme';

/**
 * Check video source object and decide if DRM is necessary or not.
 * 
 * @param videojs
 * VideoJS object
 * @param src
 * Video source object.
 */
export default class VideoSources {

    player;
    src;
    isApple;
    initialState = true;

    constructor(player) {
        this.player = player;
        this.isApple = videojs.browser.IS_ANY_SAFARI;

        if (!this.src) return;

    }

    sources(src) {

        // Set the original sources
        this.src = src;
        // Set a copy of the sources that can be changed
        let sources = src;

        if (this.isDrm()) {
            // If the sources have DRM, the eme plugins should inicilyse.
            if (this.initialState) {
                this.player.eme();
                this.initialState = false;

            }
            this.src = this.sourcersFilter();

            // console.log(this.src, this.isApple);

            if (this.isApple) {
                sources = setupFairplay(this.src[0].type, this.src[0].src, this.src[0].keySystem?.fairPlay?.licenseUri, this.src[0].keySystem?.fairPlay?.certificateUri);
            } else {

                sources = this.setupWidevidePlayReady();
            }


        }
        // console.log("Sources -------------------\n");
        // console.log(sources);
        // console.log("/--------------------------\n");
        this.player.src(sources);

        this.player.tech(true).on('licenserequestattempted', (e) => {
            // Act on event
            console.log('licenserequestattempted');
        });

    }

    getSrc() {
        return this.src;
    }

    isDrm() {
        return this.src.some((item) => {
            return item.keySystem;
        });
    }

    sourcersFilter() {

        return this.src.filter((item) => {
            return !this.isApple ? (item.keySystem?.widevine || item.keySystem?.playReady) : item.keySystem?.fairPlay;
        });
    }

    setupWidevidePlayReady() {
        return {
            src: this.src[0].src,
            type: this.src[0].type,
            keySystems: {
                "com.widevine.alpha": {
                    url: this.src[0].keySystem?.widevine?.url
                },
                "com.microsoft.playready": {
                    url: this.src[0].keySystem?.playReady?.url
                }
            }
        };
    }

}




/**
 * Set up the Aplle Fairplay. This code was given from Brightcove support
 * @param sourceType
 * @param src 
 * @param licenseUri 
 * @param certificateUri 
 */
const setupFairplay = (sourceType, src, licenseUri, certificateUri) => {

    const publisherId = '3636334164001';
    const ApplicationId = 'd7af61af-04a4-414a-9a25-60a340ec1996';


    const base64EncodeUint8Array = (input) => {
        return window.btoa(String.fromCharCode.apply(null, input));
    };

    const uint16ArrayToString = (array) => {
        //@ts-ignore
        return String.fromCharCode.apply(null, new Uint16Array(array.buffer));
    };

    const createGetCertificateFunc = (certificateUri) => {
        return (emeOptions, callback) => {

            videojs.xhr({
                uri: certificateUri,
                responseType: 'arraybuffer'
                //@ts-ignore
            }, (err, response, responseBody) => {
                if (err) {
                    console.log("ERROR createGetCertificateFunc");
                    callback(err);
                    return;
                }
                console.log("createGetCertificateFunc responseBody", new Uint8Array(responseBody));
                callback(null, new Uint8Array(responseBody));
            });
        };
    };
    //@ts-ignore
    const getContentId = (emeOptions, initData) => {
        // console.log(uint16ArrayToString(initData));
        // return skd://assetId=....
        return uint16ArrayToString(initData).split('//')[1];
    };

    //@ts-ignore
    const createGetLicenseFunc = (publisherId, applicationId, licenseUri) => {
        //@ts-ignore
        return (emeOptions, contentId, keyMessage, callback) => {

            // console.log('keyMessage', keyMessage);

            const headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/octet-stream'
            };

            if (emeOptions.emeHeaders && emeOptions.emeHeaders['BCOV-Auth']) {
                //@ts-ignore
                headers['BCOV-Auth'] = emeOptions.emeHeaders['BCOV-Auth'];
            }
            //@ts-ignore
            videojs.xhr({
                uri: licenseUri,
                method: 'POST',
                responseType: 'arraybuffer',
                //@ts-ignore
                json: {
                    /* eslint-disable camelcase */
                    publisher_id: publisherId,
                    application_id: ApplicationId,
                    key_id: contentId.split('/')[3],
                    server_playback_context: base64EncodeUint8Array(keyMessage)
                    /* eslint-enable camelcase */
                },
                headers
                //@ts-ignore
            }, (err, response, responseBody) => {
                if (err) {
                    // console.log("ERROR createGetLicenseFunc");
                    callback(err);
                    return;
                }
                //@ts-ignore
                if (response.statusCode >= 400 && response.statusCode <= 599) {
                    // Pass an empty object as the error so contrib-eme will use the default
                    // MEDIA_ERR_ENCRYPTED message
                    callback({});
                    return;
                }

                // console.log(" createGetLicenseFunc Response body", responseBody);

                callback(null, responseBody);
            });
        };
    };


    return {
        src: src,
        type: sourceType,
        keySystems: {
            'com.apple.fps.1_0': {
                getCertificate: createGetCertificateFunc(certificateUri),
                getContentId,
                getLicense: createGetLicenseFunc(publisherId, ApplicationId, licenseUri)
            }
        }
    };
};