const checkChrome = () => {
    // Get the user-agent string 
    let userAgentString = navigator.userAgent;
    // Detect Chrome 
    return userAgentString.indexOf("Chrome") > -1;

};


const checkMobileBrowser = () => {

    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return isMobile;

};


export { checkChrome, checkMobileBrowser };