import { checkChrome, checkMobileBrowser } from './browser-check';


const checkPictureInPictureSupport = () => {

    let out = false;

    let isChrome = checkChrome();
    let isMobile = checkMobileBrowser();

    //console.log('checkPictureInPictureSupport', isChrome, isMobile);

    if (isChrome && !isMobile) {
        out = true;
    }


    return out;

}


export default checkPictureInPictureSupport;