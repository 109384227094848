/**
 * Destroy player and show error message
 * @param {object} player 
 * @param {string} message 
 */
const showPlayerError = function (player, message) {
    // Player destroy
    player.dispose();
    let errorElement = document.getElementById('player-error');
    //@ts-ignore
    errorElement.style.display = 'block';
    //@ts-ignore
    errorElement.innerHTML = `<h3>ERROR</h3><p>${message}</p>`;
};
export default showPlayerError;