import 'videojs-markers';


// https://iandevlin.com/blog/2015/02/javascript/dynamically-adding-text-tracks-to-html5-video/
// https://developer.mozilla.org/en-US/docs/Web/API/VTTCue
// https://docs.videojs.com/tutorial-text-tracks.html#text-tracks,


/**
 * Mount the list of Bible Verses in the place of chapters. Apply a Bible icon.
 * 
 * @param player
 *      VideoJS player
 * @param verses
 *      array of verses of type `BibelVerses`.
 */
export default class BibelVerses {

    player;
    verses;
    track;


    constructor(player, verses) {
        this.player = player;
        this.verses = verses;
        this.track;
    }

    createCuePoints() {

        if (!this.verses.length) {

            return;
        };

        this.createTextTrack();

        this.addPoints();

        this.replaceCharpter();

    }

    createTextTrack() {
        let bibleTitle = this.verses.length > 1 ? "Bibelstellen" : "Bibelstelle";
        this.track = this.player.addTextTrack("chapters", bibleTitle, "de");

    }


    addPoints() {
        // Example
        // {
        //     "time": [
        //         4, 44
        //     ],
        //     "verse": "Mt 16,22.23"
        // }
        if (this.verses.length == 1) {
            this.track.addCue(new VTTCue(1, 0, "Anfang"));
        }

        this.verses.map((item) => {
            if (!item.time || !item.verse) {
                console.error("Invalid Bible verse schema.", item);
                return;
            }
            this.track.addCue(new VTTCue(
                item.time, // start
                //@ts-ignore
                '', // end
                item.verse)); // text
            return;
        });

        // Example
        // this.track.addCue(new VTTCue(1500, 2500, "Mt, 13,3"));
    }

    replaceCharpter() {
        let playerEl = document.getElementById(this.player.id());
        if (playerEl) {
            playerEl.querySelectorAll('div.vjs-chapters-button').forEach((el) => {
                el.querySelector('button.vjs-chapters-button').setAttribute('title', 'Bibelstellen');
                el.classList.add("vjs-bibel-verses");

            });
        }

    }


}