// Import stylesheets
import './scss/btv-player.scss';

// Import VideoJS
import videojs from 'video.js';

// Import Deutsch language
import * as deutsch from './locale/de.json';

// Import Video JS plugins
import 'videojs-contrib-quality-levels';
import 'videojs-logo';
import 'videojs-hls-quality-selector';

// Import project scripts
import {
    analyticsWatchPlayer,
    titleObserver,
} from './modules/analytics/analytics-module';
import BibelVerses from './modules/bibel-verses';
import checkPictureInPictureSupport from './modules/check-picture-in-picture-support';
import VideoJump from './modules/video-jump';
import VideoSources from './modules/video-sources';

const VERSION = "4.3.4";

if (window) {
    window.videojs = videojs;
}
// IMPORTANT. export with (window as any) to allow the function be available on the window (browser).
export function player(elementID, param) {
    // BTV configurations
    let config = {
        title: param.title ? param.title : '',
        crn: param.crn ? param.crn : '',
        contentRating: param.contentRating || '',
        serieName: param.serieName || undefined,
        genreName: param.genreName || undefined,
        drm: param.drm || null,
        preload: param.preload || 'auto',
        live: param.live || false,
        logo: param.logo === false ? false : true,
        poster: param.poster
            ? param.poster
            : 'https://www.bibeltv.de/assets/images/bibeltvstationcall.jpg',
        sources: param.sources ? param.sources : undefined,
        autoplay: param.autoplay || false,
        trackLanguage: param.trackLanguage ? param.trackLanguage : undefined,
        controls: param.controls === false ? false : true,
        muted: param.muted === true ? true : false,
        fluid: param.fluid === false ? false : true,
        pictureInPictureToggle:
            param.pictureInPictureToggle === false
                ? false
                : checkPictureInPictureSupport(),
        nativeControlsForTouch:
            param.nativeControlsForTouch === true ? true : false,
        analytics: param.analytics === false ? false : true,
        showVerses: param.showVerses === false ? false : true,
        bibleVerses:
            param.bibleVerses
                ? param.bibleVerses
                : null,
        jumpToPointFromElementListener:
            param.jumpToPointFromElementListener
                ? param.jumpToPointFromElementListener
                : null,
        plugins: param.plugins || undefined
    };


    // Video JS player Options
    let playerOptions = {
        controls: config.controls,
        autoplay: config.autoplay,
        muted: config.muted,
        preload: config.preload,
        fluid: config.fluid,
        liveui: true,
        nativeControlsForTouch: config.nativeControlsForTouch,
        language: 'de',
        plugins: config.plugins,
        //textTrackSettings: false,
        // controlBar: {
        //     currentTimeDisplay: true,
        //     durationDisplay: true,
        //     customControlSpacer: true,
        //     remainingTimeDisplay: false,
        //     liveDisplay: true,
        //     pictureInPictureToggle: config.pictureInPictureToggle,
        // },
        html5: {
            nativeTextTracks: false,
            nativeAudioTracks: false,
            nativeVideoTracks: false,
            vhs: {
                smoothQualityChange: true,
                overrideNative: !videojs.browser.IS_SAFARI
            },
        },
    };


    // Set German language as default
    videojs.addLanguage('de', deutsch);


    // Setup the Video JS player object
    const videoJsPlayer = videojs(elementID, playerOptions, () => {
        console.log(`***player is ready!***\nv${VERSION}`);

        // Play inliine to fix default Sarari overlay
        videoJsPlayer.playsinline(true);

        // Setup the video quality display
        videoJsPlayer.hlsQualitySelector({
            displayCurrentQuality: true,
        });

    });


    // Create a instance of VideoSources object
    const videoSources = new VideoSources(videoJsPlayer);

    /**
     * Set up the sources of the player.
     * @param src Sources
     */
    const sources = (src = undefined) => {
        if (!src) return;
        // Mount sources
        videoSources.sources(src);

    };

    // If logo option is true, show BTV logo on the top right of the player
    if (config.logo) {
        // BTV Player logo
        videoJsPlayer.logo({
            image: 'https://www.bibeltv.de/assets/images/btv_negativ_logo.svg',
            offsetH: 18,
            offsetV: 12,
        });

    }

    /**
     * Just show hello in the console. Test function!
     */
    const sayHello = () => console.log('hello!');

    /**
     * Play 
     */
    const play = () => videoJsPlayer.play();

    /**
     * Pause
     */
    const pause = () => videoJsPlayer.pause();

    /**
     * Reset the player
     */
    const reset = () => videoJsPlayer.reset();

    /**
     *  Clear VideoJs state
     */
    const dispose = () => videoJsPlayer.dispose();

    /**
     * Get or set the current time (in seconds)
     * @param t number
     */
    const currentTime = (t) => videoJsPlayer.currentTime(t);

    /**
     *  Gets the length in time of the video in seconds
     */
    const duration = () => videoJsPlayer.duration();

    /**
     * 
     * @returns the player
     */
    const requestPictureInPicture = () => videoJsPlayer.requestPictureInPicture();

    /**
     * Exposes the player event
     * @param {string} event 
     * @param {function} f 
     * @returns the event
     */
    const on = (event, f) => videoJsPlayer.on(event, f);

    /**
     * Set up a video poster
     * @param url string
     */
    const poster = (url) => {
        videoJsPlayer.poster(url);
    };

    /**
     * Set a CRN video
     * @param crn string
     */
    const crn = (crn) => {
        if (crn) config.crn = crn;
        return config.crn;
    };

    /**
     * Set Serie name
     * @param name string
     */
    const serieName = (name) => {
        if (name) config.serieName = name;
        return config.serieName;
    };

    /**
     * Set Genre name
     * @param name string
     */
    const genreName = (name) => {
        if (name) config.genreName = name;
        return config.genreName;
    };

    /**
     * Set the Content Rating of the video [A-F]
     * @param {string} rating 
     * @returns rating letter
     */
    const contentRating = (rating) => {
        if (rating) config.contentRating = rating;
        return config.contentRating;
    };

    /**
     * Set a Default audio Track
     * @param trackLanguage string
     */
    const trackLanguage = (lang) => {
        if (lang) config.trackLanguage = lang;
        return config.trackLanguage;
    };

    /**
     * Set a title of the video (for analytics proposal)
     * @param name string
     */
    const title = (name) => {
        if (name) {
            config.title = name;
        }
        titleObserver(config.title);
        return config.title;
    };

    /**
     * Mount the bible verses on the player
     * @param {object} bibleVerses 
     */
    const mountBibleVerves = (bibleVerses) => {

        if (bibleVerses) {
            config.bibleVerses = bibleVerses;
        }

        if (config.bibleVerses && config.showVerses) {

            const verses = new BibelVerses(videoJsPlayer, config.bibleVerses);

            verses.createCuePoints();
        }

        if (config.jumpToPointFromElementListener) {
            const jump = new VideoJump(videoJsPlayer, config.jumpToPointFromElementListener);
            jump.listener();
        }

    };

    /**
     * Set the player poster
     */
    poster(config.poster);

    /**
     * Set the player sources
     */
    sources(config.sources);

    /**
     * Set the player title
     */
    if (config.title) {
        titleObserver(config.title);
    }

    /**
     * Execute Analytics funtion
     */
    analyticsWatchPlayer(videoJsPlayer, config);

    /**
     * Mount the bible verses on the player
     */
    if (config.bibleVerses) {
        mountBibleVerves(config.bibleVerses);
    }


    return {
        elementID,
        param,
        sayHello,
        play,
        videoJsPlayer,
        pause,
        reset,
        dispose,
        currentTime,
        duration,
        poster,
        sources,
        title,
        crn,
        serieName,
        genreName,
        trackLanguage,
        contentRating,
        mountBibleVerves,
        requestPictureInPicture,
        on
    };

};


