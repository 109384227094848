const bibelVerseListener = (elmentID, fallback) => {
  const verses = document.querySelector(
    `#${elmentID} > div.vjs-control-bar > div.vjs-chapters-button.vjs-menu-button.vjs-menu-button-popup.vjs-control.vjs-button.vjs-bibel-verses > div > ul`,
  );
  if (!verses) return;

  verses.addEventListener('click', (event) => {
    if (fallback) {
      if (!event.target.classList.contains('vjs-menu-title')) {
        //@ts-ignore
        fallback(event.target.innerText);
      }
    }
  });
};

export default bibelVerseListener;
