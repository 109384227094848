/**
 * Calculate the current time of the video and 
 * execute a function each 10 seconds 
 * @param {number} lastPosition 
 * @param {number} currentPosition 
 * @param {Function} sendData 
 */
const onTimeUpdate = function (lastPosition, currentPosition, sendData) {
    var range = "";
    if (Math.round(currentPosition) - Math.round(lastPosition) === 10) {
        // set the range and add it to the evt object
        range = Math.round(lastPosition).toString() + ".." + Math.round(currentPosition).toString();
        // Execute function
        sendData({
            currentPosition: currentPosition,
            range: range
        });
    }
};

export default onTimeUpdate;