import sendGoogleAnalyticsEvents from './ga-events';
import onTimeUpdate from './on-time-update';
import showPlayerError from '../show-player-error';
import bibelVerseListener from './bibelverse-listener';



let videoTitle = '';
const titleObserver = (title) => {
    videoTitle = title;
};

const handleAudioTracks = (tracks, lang) => {
    if (typeof tracks !== "object") return;
    if (lang && tracks.length > 1) {

        for (var i = 0; i < tracks.length; i++) {
            var track = tracks[i];

            if (track.language === lang) {
                track.enabled = true;
                return;
            } else {
                track.enabled = false;
            }
            // videojs.log(track);
        }
    }
};

const analyticsWatchPlayer = function (player, videoInfo) {

    if (!videoInfo.analytics) return;

    const elementID = player.id();

    // If is a Livestream
    const isLive = videoInfo.live;

    let lastPosition = 0;
    let videoDuration = 0;



    player.on('loadstart', () => {
        // Bibel Verses click events
        bibelVerseListener(elementID);

        //set the alt attribute to the logo img tag. The default plugin does not added it.
        const logoEl = document.querySelector(".vjs-logo-content").firstElementChild;
        logoEl.setAttribute("alt", "Bibel TV");
    });

    player.on('loadeddata', () => {

        videoDuration = player.duration();

        // Set default audio track language if it given from the player options
        if (videoInfo.trackLanguage) {
            handleAudioTracks(player.audioTracks(), videoInfo.trackLanguage);
        }

        sendGoogleAnalyticsEvents(
            'video_ready', {
            crn: videoInfo.crn,
            title: videoTitle,
            contentRating: videoInfo.contentRating,
            serie: videoInfo.serieName,
            genre: videoInfo.genreName,
            duration: videoDuration,
            isLive: isLive
        });





    });

    player.one('play', () => {
        sendGoogleAnalyticsEvents(
            'video_start', {
            crn: videoInfo.crn,
            title: videoTitle,
            contentRating: videoInfo.contentRating,
            serie: videoInfo.serieName,
            genre: videoInfo.genreName,
            duration: videoDuration,
            currentTime: Math.floor(player.currentTime()),
            isLive: isLive
        });
        // Set apropriate max-height to the menu content.
        //console.log(typeof elementID)
        let playerElement =
            typeof player === 'object'
                ? player
                : document.getElementById(elementID);
        //@ts-ignore
        let vjsMenu = document.getElementById(elementID).querySelectorAll('ul.vjs-menu-content') || null;
        if (vjsMenu) {
            vjsMenu.forEach((el) => {
                //@ts-ignore
                el.style.cssText = `max-height: ${playerElement.offsetHeight - 38}px`;
            });
        }
    });

    player.on('pause', () => {
        sendGoogleAnalyticsEvents(
            'video_pause', {
            crn: videoInfo.crn,
            title: videoTitle,
            contentRating: videoInfo.contentRating,
            serie: videoInfo.serieName,
            genre: videoInfo.genreName,
            duration: videoDuration,
            currentTime: Math.floor(player.currentTime()),
            isLive: isLive
        });
    });

    player.on('playing', () => {
        sendGoogleAnalyticsEvents(
            'video_resume', {
            crn: videoInfo.crn,
            title: videoTitle,
            contentRating: videoInfo.contentRating,
            serie: videoInfo.serieName,
            genre: videoInfo.genreName,
            duration: videoDuration,
            currentTime: Math.floor(player.currentTime()),
            isLive: isLive
        });

    });

    player.on('timeupdate', () => {
        let currentTime = player.currentTime();
        // let remainingTime = player.remainingTime();

        onTimeUpdate(lastPosition, currentTime, (res) => {

            // https://support.google.com/admanager/answer/2759433?hl=en
            let ratio = Math.floor(currentTime) / videoDuration;
            let fration = ratio - Math.floor(ratio);
            let percentView = Math.round(100 * fration);

            // Update the last position of the time
            lastPosition = res.currentPosition;
            sendGoogleAnalyticsEvents(
                'video_progress', {
                crn: videoInfo.crn,
                title: videoTitle,
                contentRating: videoInfo.contentRating,
                serie: videoInfo.serieName,
                genre: videoInfo.genreName,
                duration: videoDuration,
                currentTime: Math.floor(currentTime),
                percent: percentView,
                viewTime: 10,
                isLive: isLive
            });
        });
    });

    player.on('fullscreenchange', () => {
        if (player.isFullscreen()) {
            sendGoogleAnalyticsEvents(
                'video_fullscreen', {
                crn: videoInfo.crn,
                title: videoTitle,
                contentRating: videoInfo.contentRating,
                serie: videoInfo.serieName,
                genre: videoInfo.genreName,
                duration: videoDuration,
                currentTime: Math.floor(player.currentTime()),
                isLive: isLive
            });
        }
    });

    player.on('enterpictureinpicture', () => {
        sendGoogleAnalyticsEvents(
            'video_picture_in_picture', {
            crn: videoInfo.crn,
            title: videoTitle,
            contentRating: videoInfo.contentRating,
            serie: videoInfo.serieName,
            genre: videoInfo.genreName,
            duration: videoDuration,
            currentTime: Math.floor(player.currentTime()),
            isLive: isLive
        });

    });

    player.on('seeking', () => {
        lastPosition = player.currentTime();
        sendGoogleAnalyticsEvents(
            'video_seek', {
            crn: videoInfo.crn,
            title: videoTitle,
            contentRating: videoInfo.contentRating,
            serie: videoInfo.serieName,
            genre: videoInfo.genreName,
            duration: videoDuration,
            currentTime: Math.floor(player.currentTime()),
            isLive: isLive
        });
    });

    player.on('ended', () => {
        // console.log('Awww...over so soon?!');
        sendGoogleAnalyticsEvents(
            'video_complete', {
            crn: videoInfo.crn,
            title: videoTitle,
            contentRating: videoInfo.contentRating,
            serie: videoInfo.serieName,
            genre: videoInfo.genreName,
            duration: videoDuration,
            currentTime: Math.floor(player.currentTime()),
            isLive: isLive
        });
    });

    player.on('error', (err) => {
        err.stopImmediatePropagation();
        var error = player.error();
        //@ts-ignore
        console.log('error!', error.code, error.message);

        if (err.type === 'error') {

            sendGoogleAnalyticsEvents(
                'video_error', {
                crn: videoInfo.crn,
                title: videoTitle,
                error: `${error.code} - ${error.message}`,
                contentRating: videoInfo.contentRating,
                serie: videoInfo.serieName,
                genre: videoInfo.genreName,
                duration: videoDuration | 0,
                currentTime: Math.floor(player.currentTime()) | 0,
                isLive: isLive
            });

            //document.querySelector('.vjs-big-play-button').style.display = 'none';

            let message = `Leider ist das von Ihnen gewünschte Video derzeit nicht verfügbar. Sie können den Fehler per E-Mail an <a href="mailto:mediathek@bibeltv.de">mediathek@bibeltv.de</a> melden. Wir kümmern uns darum, dass das Video bald wieder online steht.`;

            if (error?.code === 3 || error?.code === 5) message = `<div style="display:block;">Bitte wechseln Sie den Browser, um dieses Video abzuspielen. Ihr Browser unterstützt notwendige lizenzrechtliche Einstellungen leider nicht. Hier finden Sie mögliche. <div><a href="https://www.google.com/chrome/" target="_blank">Chrome</a></div> <div><a href="https://www.microsoft.com/de-de/edge" target="_blank">Edge</a></div>
            </div>`;

            showPlayerError(player, message);

            /**
                   *  Name	Type	    Description
                      0	    string	    MEDIA_ERR_CUSTOM
                      1	    string	    MEDIA_ERR_ABORTED
                      2	    string	    MEDIA_ERR_NETWORK
                      3	    string	    MEDIA_ERR_DECODE
                      4	    string	    MEDIA_ERR_SRC_NOT_SUPPORTED
                      5	    string	    MEDIA_ERR_ENCRYPTED 
                   */
        }
    });
}; // analyticsWatchPlayer

export { videoTitle, titleObserver, analyticsWatchPlayer };
